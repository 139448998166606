import React from "react";
import { useNavigate } from "react-router-dom";
import { useFlags } from 'flagsmith/react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import { formatDate } from "../utils/validators";
import { useToggleContext } from "../utils/ToggleContext";
import { useAuthContext } from "../utils/AuthContext";
import { continuePostLogin } from "../utils/auth";
import { updateUserMeta, createSubscriptionStatus } from "../utils/services";
import { SUBSCRIPTION_STATUS } from "../utils/consts";

const Agreements = () => {
  const flags = useFlags(['freemium_access']);
  const freemiumAccess = flags.freemium_access.enabled;

  const {box1checked, box2checked, setBox1Checked, setBox2Checked} = useToggleContext();

  const boxesChecked = [box1checked, box2checked].filter(element => element).length === 2;

  const {sessionState, userId, authorized} = useAuthContext();

  const navigate = useNavigate();

  const handleNextStep = async () => {
    try {
      const userMeta = {
        "user_id": userId,
        "user_metadata": {
          "signedAgreements": true
        }
      };

      await updateUserMeta(userMeta);

      if (!authorized) {
        if (freemiumAccess) {
          navigate("/choices");
        } else {
          navigate("/questions");
        }
      } else {
        const userData = {
          userId: userId,
          subscriptionStatus: SUBSCRIPTION_STATUS.PRESCRIPTION_ACTIVE,
          date: formatDate(new Date())
        };

        await createSubscriptionStatus(userData);
        
        continuePostLogin(sessionState, userId, {subscriptionStatus: SUBSCRIPTION_STATUS.PRESCRIPTION_ACTIVE});
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="heading" sx={{
        mx: 2,
        mb: 1,
      }}>
        Agreements
      </Typography>

      <Typography variant="body" sx={{
        mx: 2,
        my: 1,
      }}>
        To continue, check all the boxes below to acknowledge our agreements.
      </Typography>

      <FormGroup sx={{m: 2}}>
        <FormControlLabel control={
          <Checkbox 
            color="customPurple"
            checked={box1checked} 
            onChange={(event) => {
              setBox1Checked(event.target.checked);
            }}
          />
        }
          label={
            <Typography variant="bodySmall">
              I agree to Stanza’s&nbsp;
              <Link color="inherit" href="https://swingtherapeutics.com/terms-of-use/">Terms of Use</Link>
              &nbsp;and&nbsp;
              <Link color="inherit" href="https://swingtherapeutics.com/privacy-policy/">Privacy Policy</Link>
            </Typography>
          }
        />
        <FormControlLabel control={
          <Checkbox 
            color="customPurple"
            checked={box2checked} 
            onChange={(event) => {
              setBox2Checked(event.target.checked);
            }}
          />
        } 
          label={<Typography variant="bodySmall">I agree to receive communication from Swing Therapeutics</Typography>}
        />
      </FormGroup>

      <Button
        size="large"
        variant="contained"
        color="customRed"
        sx={{
          borderRadius: '25px',
          m: 2,
        }} 
        disabled={!boxesChecked}
        onClick={handleNextStep}
      >
        Submit
      </Button>
    </Box>
  )
}

export default Agreements;
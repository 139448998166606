import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';

import { REACT_APP_FLAGSMITH_ENVIRONMENT_ID } from "./config";

export const FeatureFlagProvider = ({children}) => {
  return (
    <FlagsmithProvider
      options={{
        environmentID: REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
      }}
      flagsmith={flagsmith}>
      {children}
    </FlagsmithProvider>
  );
}
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../utils/AuthContext";

const Home = () => {
  const {page} = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${page}`);
  }, [page, navigate]);

  return ( 
    <>
    </>
  )
};

export default Home;

import { useState, createContext, useContext } from "react";

const ToggleContext = createContext();

export const ToggleProvider = ({children}) => {
  const [box1checked, setBox1Checked] = useState(false);
  const [box2checked, setBox2Checked] = useState(false);

  return (
    <ToggleContext.Provider value={{box1checked, box2checked, setBox1Checked, setBox2Checked}}>
      {children}
    </ToggleContext.Provider>
  )
}

export const useToggleContext = () => {
  return useContext(ToggleContext);
};
import moment from "moment";
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import isEmail from "validator/lib/isEmail";

export const isBirthdateValid = (birthdate) => {
  if (birthdate.isValid()) {
    if (birthdate <= moment()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const isPhoneValid = (phone) => {
  return isPossiblePhoneNumber(phone);
}

export const isEmailValid = (email) => {
  return isEmail(email);
}

export const formatDate = (date) => {
  return moment(date).startOf("day");
}

export const formatPhoneNumber = (phone) => {
  return formatPhoneNumberIntl(phone);
}
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getConfig } from "./config";
import history from "./utils/history";
import "./utils/firebase";

import { AuthProvider } from "./utils/AuthContext";
import { ToggleProvider } from "./utils/ToggleContext";
import { FeatureFlagProvider } from "./utils/FeatureFlagContext";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const theme = createTheme({
  palette: {
    customRed: {
      main: '#AF444A',
      contrastText: '#ffffff',
    },
    customBlue: {
      main: '#3A3658',
      contrastText: '#ffffff',
    },
    customBlack: {
      main: '#000000',
      contrastText: '#000000',
    },
    customPurple: {
      main: '#6C6DB9',
    },
    customBrown: {
      main: '#7D3438',
    },
    customGreen: {
      main: '#61B2AD'
    },
    action: {
      disabledBackground: alpha('#AF444A', 0.5),
      disabled: '#fff'
    }
  },

  typography: {
    heading: {
      fontFamily: 'Karma',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '36px',
    },
    body: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '26px',
    },
    bodyBold: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '26px',
    },
    bodySmall: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
    },
    bodyLarge: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    bodySpacing: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px', 
      letterSpacing: '1.5px',
    },
    button: {
      textTransform: 'none',
    }
  }
});

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <FeatureFlagProvider>
            <ToggleProvider>
              <App />
            </ToggleProvider>
          </FeatureFlagProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const jwt = require("jsonwebtoken");

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const secret = process.env.REACT_APP_AUTH0_SECRET;

export const continuePostLogin = (sessionState, userId, data) => {
  const token = jwt.sign({
    state: sessionState,
    sub: userId,
    iss: domain,
    signature: secret,
    ...data
  }, secret, { expiresIn: "1d" });

  const redirect = `https://${domain}/continue?state=${sessionState}&token=${token}`;

  window.location.assign(redirect);
}
import axios from "axios";
import { REACT_APP_SWING_API_URL } from '../utils/config';

export const updateUserDoc = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/updateUserDoc`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
}

export const updateUserMeta = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/updateUserMeta`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
}

export const createSubscriptionStatus = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/subscriptionStatus`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
}

export const activatePrescription = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/activatePrescription`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
}

export const contactUs = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/contactUs`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
}

export const checkSessionReset = async (data) => {
  const config = {
    method: "post",
    url: `${REACT_APP_SWING_API_URL}/user/checkSessionReset`,
    headers: { 
      "Content-Type": "application/json"
    },
    data: data
  };

  const response = await axios(config);
  console.log(response.data);
  return response.data;
}

export const deleteUserProgram = async (userId, program ='stanzaACT1') => {
  const config = {
    method: "delete",
    url: `${REACT_APP_SWING_API_URL}/firebase/users/${encodeURIComponent(userId)}/programs/${program}`,
  };

  const response = await axios(config);
  console.log(response.data);
}
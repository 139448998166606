import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { TextMask } from "./MaskInput";

import { isEmailValid, isPhoneValid, formatPhoneNumber } from "../utils/validators";
import { useAuthContext } from "../utils/AuthContext";
import { contactUs } from "../utils/services";

const Contact = () => {
  const placeholderText = {
    "Issue with prescription": "Please provide your prescribing doctor’s name, and the date of your prescription.",
    "Report a problem": "Please describe what you were doing when the problem occurred.",
    "Request a feature": "Please describe your feature.",
    "Other": "Please describe how we may help.",
  };

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [topic, setTopic] = useState("Issue with prescription");
  const [message, setMessage] = useState("");
  const [messageLabel, setMessageLabel] = useState("");
  const [messagePlaceholder, setMessagePlaceholder] = useState(placeholderText["Issue with prescription"]);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [emptyError, setEmptyError] = useState(false);

  const handleValidate = () => {
    if (email) {
      if (isEmailValid(email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    } else {
      setEmailError(false);
    }

    if (phone) {
      if (isPhoneValid(phone)) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    } else {
      setPhoneError(false);
    }
  }

  const {userId, inMobile} = useAuthContext();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => !inMobile ? navigate("/questions") : setOpen(false);

  const handleNextStep = async () => {
    if (!message || (!inMobile && !email)) {
      setEmptyError(true);
      return;
    }

    setEmptyError(false);

    const contactData = {
      "userId": userId,
      "email": email,
      "topic": topic,
      "message": message,
      "phone": isPhoneValid(phone) ? formatPhoneNumber(phone) : ""
    };

    await contactUs(contactData);
    
    handleOpen();
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Dialog   
        PaperProps={{
          style: { 
            backgroundColor: '#F7F3F2',
            borderRadius: '25px',
         }
        }}
        open={open}
        onClose={handleClose} 
      >
        <DialogTitle variant="bodySmall">
          📫 Message Sent
        </DialogTitle>

        <IconButton
          color="customBlack"
          sx={{
            position: 'absolute',
            right: 6,
            top: 6,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent variant="bodySmall">
          Thanks for reaching out. Our Support Team will reply by email as soon as possible. 
        </DialogContent>

        <Button 
          size="large" 
          variant="outlined"
          color="customBlack"
          sx={{
            borderRadius: '25px',
            m: 3,
          }}
          onClick={handleClose}
        >
          Got it
        </Button>
      </Dialog>

      <Typography variant="heading" sx={{
        mx: 2,
        mb: 1,
      }}>
        Contact Us
      </Typography>

      {emptyError &&
        <Alert
          icon={false}
          severity="error"
          sx={{m: 2}}
          onClose={() => {setEmptyError(false);}}
        >
          <Typography variant="bodySmall" color="#DB4A45">
            Please fill out all fields before sending.
          </Typography>
        </Alert>
      }

      {!inMobile &&
        <TextField
          sx={{m: 2}}
          label="Email address"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          error={emailError}
          helperText={emailError ? "Please enter a valid email address." : null}
          onBlur={handleValidate}
        />
      }

      {!inMobile &&
        <TextField
          sx={{m: 2}}
          label="Phone number (optional)"
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
          error={phoneError}
          helperText={phoneError ? "Please enter a valid phone number." : null}
          onBlur={handleValidate}
          InputProps={{
            inputComponent: TextMask,
          }}
        />
      }

      <Select
        sx={{m: 2}}
        value={topic}
        onChange={(event) => {
          setTopic(event.target.value);
          setMessagePlaceholder(placeholderText[event.target.value]);
        }}
      >
        <MenuItem value={"Issue with prescription"}>Issue with prescription</MenuItem>
        <MenuItem value={"Report a problem"}>Report a problem</MenuItem>
        <MenuItem value={"Request a feature"}>Request a feature</MenuItem>
        <MenuItem value={"Other"}>Other</MenuItem>
      </Select>

      <TextField
        sx={{m: 2}}
        multiline
        rows={4}
        label={messageLabel}
        onClick={() => {
          setMessageLabel("Your message");
        }}
        onBlur={() => {
          setMessageLabel("");
        }}
        placeholder={messagePlaceholder}
        value={message}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
      />

      <Typography variant="bodySmall" sx={{m: 2}}>
        When you send this form, its information will be shared with the Swing Therapeutics Support Team. The support team will send a reply to the email address on record for this account.
      </Typography>

      <Button
        size="large"
        variant="contained"
        color="customRed"
        sx={{
          borderRadius: '25px',
          m: 2,
        }} 
        disabled={emailError || phoneError}
        onClick={handleNextStep}
      >
        Send
      </Button>

      {!inMobile &&
        <Button
          size="large"
          variant="contained"
          color="customBlue"
          sx={{
            borderRadius: '25px',
            m: 2,
          }} 
          onClick={handleClose}
        >
          Cancel
        </Button>
      }
    </Box>
  )
}

export default Contact;
import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import stanzaLogo from "../assets/stanzaLogo.svg";

import { useAuthContext } from "../utils/AuthContext";
import { continuePostLogin } from "../utils/auth";

const Congratulations = () => {
  const {sessionState, userId, inMobile} = useAuthContext();

  const handleNextStep = () => {
    continuePostLogin(sessionState, userId, {});
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" justifyContent="center">
        <img className="app-logo" src={stanzaLogo} alt="Stanza logo"/>
      </Box>

      <Typography variant="heading" sx={{
        textAlign: 'center',
        m: 3,
      }}>
        You are all set!
      </Typography>

      <Typography variant="body" sx={{
        textAlign: 'center',
        mx: 5,
      }}>
        We’re excited to offer you this innovative experience that will teach you new ways to manage your symptoms and regain control over your life. 
      </Typography>

      {!inMobile &&
        <Button
          size="large"
          variant="contained"
          color="customRed"
          sx={{
            borderRadius: '25px',
            mx: 5,
            my: 3,
          }}
          onClick={handleNextStep}
        >
          Begin
        </Button>
      }
    </Box>
  )
}

export default Congratulations;
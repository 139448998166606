import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import stanzaLogo from "../assets/stanzaLogo.svg";

const Steps = () => {
  const steps = [
    {
      label: "PRESCRIPTION",
      description: "Confirm your prescription",
    },
    {
      label: "AGREEMENTS",
      description: "Agree to our Terms of Use and Privacy Policy",
    },
  ];

  const {state} = useLocation();
  const { activatedPrescription, signedAgreements } = state;

  const [title, setTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const handleComplete = useCallback((step) => {
    const newCompleted = completed;
    newCompleted[step] = true;
    setCompleted(newCompleted);
  }, [completed]);

  useEffect(() => {
    if (!activatedPrescription && !signedAgreements) {
      setTitle("Welcome! Please complete the following to continue.");
    } else if (activatedPrescription && !signedAgreements) {
      setTitle("Welcome back! You need to agree to our terms and policies.");
      setActiveStep(1);
      handleComplete(0);
    } else if (!activatedPrescription && signedAgreements) {
      setTitle("Welcome back! You need to confirm your prescription.");
      handleComplete(1);
    }
  }, [activatedPrescription, signedAgreements, handleComplete]);

  const navigate = useNavigate();

  const handleNextStep = () => {
    if (activeStep === 0) {
      navigate("/questions");
    } else if (activeStep === 1) {
      navigate("/agreements");
    }
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" justifyContent="center">
        <img className="app-logo" src={stanzaLogo} alt="Stanza logo"/>
      </Box>

      <Typography variant="heading" sx={{
        textAlign: 'center',
        m: 3,
      }}>
        {title}
      </Typography>

      <Stepper orientation="vertical" activeStep={activeStep} sx={{mx: 3}}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}
            sx={{
              '& .MuiStepIcon-root': 
              {
                color: '#ACA29C'
              },
              '& .MuiStepLabel-label': 
              {
                color: '#ACA29C'
              },
              '& .MuiStepIcon-root.Mui-active': 
              {
                color: '#E78D44'
              },
              '& .MuiStepLabel-label.Mui-active': 
              {
                color: '#A26330'
              },
              '& .MuiStepIcon-root.Mui-completed': 
              {
                color: '#80746F'
              },
              '& .MuiStepLabel-label.Mui-completed': 
              {
                color: '#665A53'
              },
            }}
          >
            <StepLabel>
              <Typography variant="bodySpacing">
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="bodyBold" color="#5C381B" sx={{fontFamily: 'Karma'}}>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Button
        size="large"
        variant="contained"
        color="customRed"
        sx={{
          borderRadius: '25px',
          m: 3
        }}
        onClick={handleNextStep}
      >
        Continue
      </Button>
    </Box>
  )
}

export default Steps;
import { useState, useEffect, createContext, useContext } from "react";

const jwt = require("jsonwebtoken");

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [sessionState, setSessionState] = useState(null);
  const [userId, setUserId] = useState("");
  const [page, setPage] = useState("");
  const [authorized, setAuthorized] = useState(false);

  const [inMobile, setInMobile] = useState(false);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    setSessionState(params.state);

    const sessionToken = jwt.decode(params.session_token);

    setUserId(sessionToken?.user_id ?? params.user_id ?? "");
    setPage(sessionToken?.page ?? "agreements");
    setAuthorized(sessionToken?.authorized ?? false)

    setInMobile(params.inMobile ?? false);
  }, []);

  return (
    <AuthContext.Provider value={{sessionState, userId, page, authorized, inMobile}}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  return useContext(AuthContext);
}